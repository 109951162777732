import { DatePickerMode, DateRange, DateStepAction } from "~/types/general";

export const useSetDateStep = (
  datePickerMode: DatePickerMode,
  selectedDate: any,
  dateStepAction: DateStepAction,
  options?: {
    noPlusDay?: boolean
  }
): {
  newDate: any;
} => {
  switch (datePickerMode) {
    case DatePickerMode.QUARTER:
      selectedDate = [
        new Date(new Date(selectedDate[0]).setMonth(selectedDate[0].getMonth() + (dateStepAction === DateStepAction.PREV ? -3 : 3))),
        new Date(new Date(selectedDate[1]).setMonth(selectedDate[1].getMonth() + (dateStepAction === DateStepAction.PREV ? -3 : 3))),
      ];
      break;
    case DatePickerMode.ALL:
    case DatePickerMode.WEEK:
      //  get range between dates
      const date1 = new Date(new Date(selectedDate[0]).setHours(0,0,0,0));
      const date2 = new Date(new Date(selectedDate[1]).setHours(23, 59,59,0));
      const diffTime = Math.abs(date2.getTime() - date1.getTime())
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      selectedDate = [
        date1.setDate(
          date1.getDate() +
            (dateStepAction === DateStepAction.PREV ? -diffDays : diffDays)
        ),
        date2.setDate(
          date2.getDate() +
            (dateStepAction === DateStepAction.PREV ? -diffDays : diffDays)
        ),
      ];

      break;
    case DatePickerMode.YEAR:
      selectedDate =
        selectedDate + (dateStepAction === DateStepAction.PREV ? -1 : 1);
      break;
    case DatePickerMode.MONTH:
      // calculate year if you go from december to january or january to december according to dateStepAction
      const year =
        selectedDate.month === 11 && dateStepAction === DateStepAction.NEXT
          ? selectedDate.year + 1
          : selectedDate.month === 0 && dateStepAction === DateStepAction.PREV
          ? selectedDate.year - 1
          : selectedDate.year;

      // calculate month if you go from december to january or january to december according to dateStepAction (prev or next) and check
      const month =
        dateStepAction === DateStepAction.NEXT
          ? selectedDate.month === 11
            ? 0
            : selectedDate.month + 1
          : selectedDate.month === 0
          ? 11
          : selectedDate.month - 1;

      selectedDate = {
        year,
        month,
      };

      break;
    case DatePickerMode.WEEK:
      selectedDate = [
        selectedDate[0] + (dateStepAction === DateStepAction.PREV ? -7 : 7),
        selectedDate[1] + (dateStepAction === DateStepAction.PREV ? -7 : 7),
      ];
      break;
    case DatePickerMode.DAY: {
      const date = new Date(selectedDate);

      date.setDate(
        date.getDate() + (dateStepAction === DateStepAction.PREV ? -1 : 1)
      );

      selectedDate = date;
    }
  }

  return {
    newDate: selectedDate,
  };
};
