import { Media, SidebarPrefix } from "./general";
import { InventorySingle, InventoryStatus } from "./inventory";
import { Order } from "./orders";
import { Product, ProductType } from "./products";

export interface Modifier {
  id: number;
  description: string;
  name: string;
  addons: Addon[];
  products_count: number;
  image?: Media;
  taxons: {
    id: number;
    name: string;
    slug: string;
    taxons: {
      id: number;
      name: string;
      slug: string;
    }[];
  }[];
}

export interface ModifierManage {
  id?: number;
  taxonomy: {
    name: string;
    value: number;
  }[];
  description: string;
  name: string;
  addons: AddonManage[];
  taxons: {
    name: string;
    value: number;
  }[];
}

export interface Addon {
  id: number;
  name: string;
  slug?: string;
  type: string;
  limit?: number;
  fields?: {
    name: string;
    value: number;
    slug?: string;
    measurment_unit?: string;
    round_to?: number;
    min?: number;
    max?: number;
    in_pack?: number;
  }[];
  settings?: {
    measurment_unit?: string;
    round_to?: number;
    min?: number;
    max?: number;
    in_pack?: number;
  };
  is_required: boolean;
}
export interface AddonManage {
  id?: number;
  name: string;
  slug?: string;
  type: string;
  limit?: number;
  fields?: AddonManageField[];
  settings?: {
    measurment_unit?: string;
    round_to?: number;
    min?: number;
    max?: number;
    in_pack?: number;
    show_online?:boolean;
  };
  is_required: boolean;
}

export interface AddonManageField {
  name: string;
  value: number;
  slug?: string;
  measurment_unit?: string;
  round_to?: number;
  min?: number;
  max?: number;
  in_pack?: number;
}
export interface SelectedAddons {
  name: string;
  value: number | string | string[];
  modifier_id?: number;
  type?: string;
  price?: number | string;
}

export enum ModifiersTypes {
  MULTIPLE = "checkbox",
  SINGLE = "radio-button",
  LENGTH = "length",
}

// SIDEBAR
export const SidebarPage = {
  MODIFIERS_CONFIGURE: `${SidebarPrefix.MODIFIERS}-configure`,
  ADDON_CONFIGURE: `${SidebarPrefix.MODIFIERS}-add-ons-configure`,
  MODIFIERS_FILTERS: `${SidebarPrefix.MODIFIERS}-filters`,
};
