import { OrderStatus } from "~/types/orders";

export interface PrintReportPayload {
  status: OrderStatus[];
  product: number[];
  lines?: boolean;
  quantity?: boolean;
  unit_check?: number[];
  customer?: number[];
  credit_service?: boolean;
  freight_company?: number[];
  state?: string[];
  exclude_freight_companies?: boolean;
  date_from?: string;
  date_to?: string;
  sort?: string;
  type?: string[];
  delivery_run?: number[];
  taxonomies?: number[];
  exclude_pick_up?: boolean;
  customer_tag?: number[];
  attribute?: number[];
  is_customer_exclusion?: boolean;
  is_customer_taxonomy_exclusion?: boolean;
  is_invoice_date_exclusion?: boolean;
  is_taxonomy_exclusion?: boolean;
  is_attribute_exclusion?: boolean;
  code?: boolean;
  simplified?: boolean;
}

export interface TaskEvent {
  task_id: number;
  message?: string;
  status?: TaskStatus;
  processed?: number;
  url?: string;
  filename?: string;
  content_type?: string;
}

export enum OrderReportType {
  PRODUCT_QUANTITY = "product-quantity",
  ORDER_QUANTITY = "order-quantity",
  UNIT_CHECK = "unit-check-report",
  DEBTOR = "debtor-report",
  LOADING_MANIFEST_REPORT = "loading-manifest-report",
  DELIVERY_MANIFEST_REPORT = "delivery-manifest-report",
  SALES = "sales-report",
  PRODUCT_SALES = "product-sales-report",
  ORDERS = "orders-report"
}

export enum TaskStatus {
  PENDING = "pending",
  PROCESSING = "processing",
  SUCCEED = "succeed",
  FAILED = "failed",
}
