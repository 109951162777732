import { defineStore } from "pinia";
import {
  Filters,
  Meta,
  ResponseType,
  Option,
  RequestParams,
  OptionAction,
  Image,
} from "~/types/general";
import {
  Modifier,
  ModifierManage,
  AddonManage,
  Addon,
  ModifiersTypes,
} from "~/types/modifiers";

export const useModifiersStore = defineStore("modifiers", () => {
  // State
  const modifiers = ref<Modifier[]>([]);
  const page = ref(1);
  const perPage = ref(40);
  const meta = ref<RequestParams>({});
  const totalItems = ref<number | null>(null);
  const lastPage = ref<number | null>(null);
  const isLoading = ref(false);

  const editingModifier = ref<ModifierManage>({} as ModifierManage);
  const viewingModifier = ref<Modifier>({} as Modifier);
  const editingAddon = ref<AddonManage>({} as AddonManage);
  const viewingAddon = ref<AddonManage>({} as AddonManage);
  const image = ref<Image>({} as Image);

  const typeOptions = ref([
    {
      name: "Checkbox",
      value: ModifiersTypes.MULTIPLE,
    },
    {
      name: "Radio",
      value: ModifiersTypes.SINGLE,
    },
    {
      name: "Length",
      value: ModifiersTypes.LENGTH,
    },
  ]);

  const modifiersOptions = ref([
    {
      name: "Edit",
      value: OptionAction.EDIT,
    },
    {
      name: "Delete",
      value: OptionAction.DELETE,
    },
  ]);

  const filters = ref<Filters>({});

  // Sidebar
  const isOpenSidebar = ref(false);

  // Actions

  // Get Modifiers
  async function getModifiers(
    payload?: any
  ): Promise<ResponseType<Modifier[]>> {
    let response: any;
    try {
      response = await useVaniloApi("/modifiers", {
        params: {
          ...(payload && { ...payload }),
        },
      });
    } catch (error) {
      // useErrorNotifications(error);
      console.log(error);
    }
    return response;
  }

  // Get Single Modifier
  async function getSingleModifier(
    id: number
  ): Promise<ResponseType<Modifier>> {
    let response: any;
    try {
      response = await useVaniloApi(`/modifiers/${id}`);
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    viewingModifier.value = response?.data;
    editingModifier.value = {
      id: response?.data.id,
      description: response?.data.description,
      name: response?.data.name,
      addons: response?.data.addons,
      taxonomy: response?.data.taxons.map((i) => ({
        name: i.name,
        value: i.id,
      })),
      taxons: response?.data.taxons
        .map((i) => i.taxons)
        .flat()
        .map((t) => ({
          name: t.name,
          value: t.id,
        })),
      settings: response?.data.settings,
    };
    return response;
  }

  // Update Modifier
  async function updateModifier(
    payload: ModifierManage
  ): Promise<ResponseType<Modifier>> {
    let response: any;
    const { taxonomy, taxons, addons, ...other } = payload;
    let body = {
      ...other,
      addons: addons.map((i) => {
        const { fields, settings, limit, ...other } = i;
        if (i.type === ModifiersTypes.LENGTH) {
          return { ...other, settings: settings };
        } else if (i.type === ModifiersTypes.MULTIPLE) {
          return {
            ...other,
            limit: limit,
            fields: fields,
            settings: { show_online: settings?.show_online || false },
          };
        } else {
          return {
            ...other,
            fields: fields,
            settings: { show_online: settings?.show_online || false },
          };
        }
      }),
      taxons: taxons.map((i) => i.value),
    };
    try {
      response = await useVaniloApi(`/modifiers/${payload.id}`, {
        method: "POST",
        body: body,
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    return response;
  }

  // Update Modifier Addons
  async function updateModifierAddons(
    payload?
  ): Promise<ResponseType<Modifier>> {
    let response: any;
    let body = {
      id: payload || editingModifier.value.id,
      addons:
        payload ||
        editingModifier.value.addons.map((i) => {
          const { limit, fields, settings, ...other } = i;
          if (i.type === ModifiersTypes.LENGTH) {
            return { ...other, settings: settings };
          } else if (i.type === ModifiersTypes.MULTIPLE) {
            return {
              ...other,
              limit: limit,
              fields: fields,
              settings: { show_online: settings?.show_online || false },
            };
          } else {
            return {
              ...other,
              fields: fields,
              settings: { show_online: settings?.show_online || false },
            };
          }
        }),
    };
    try {
      response = await useVaniloApi(`/modifiers/${body.id}`, {
        method: "POST",
        body: body,
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    return response;
  }

  // Create Modifier
  async function createModifier(
    payload: ModifierManage
  ): Promise<ResponseType<Modifier>> {
    let response: any;
    const { taxonomy, taxons, ...other } = payload;
    let body = {
      ...other,
      taxons: taxons.map((i) => i.value),
    };
    try {
      response = await useVaniloApi(`/modifiers`, {
        method: "POST",
        body: body,
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    return response;
  }

  // Delete Modifier
  async function deleteModifier(id): Promise<ResponseType<any[]>> {
    let response: any;
    try {
      response = await useVaniloApi(`/modifiers/${id}`, {
        method: "DELETE",
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    return response;
  }

  // Upload Image Modifier
  async function uploadImageModifier(data?, id?) {
    let response: any;
    let formData = new FormData();
    formData.append("media", data.media);
    try {
      response = await useVaniloApi(`/modifiers/${id}/gallery`, {
        method: "POST",
        body: formData,
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    if (response) {
      image.value.media = response?.data.url;
      image.value.title = response?.data.title;
      image.value.description = response?.data.description;
    }
    return response;
  }

  // Delete Image Modifier
  async function deleteImageModifier(id?) {
    let response: any;
    try {
      response = await useVaniloApi(`modifiers/gallery/${id}`, {
        method: "DELETE",
      });
    } catch (error) {
      useErrorNotifications(error);
      console.log(error);
    }
    return response;
  }

  return {
    modifiers,
    page,
    perPage,
    meta,
    totalItems,
    lastPage,
    isLoading,
    editingModifier,
    viewingModifier,
    editingAddon,
    viewingAddon,
    image,
    typeOptions,
    modifiersOptions,
    filters,
    isOpenSidebar,
    getModifiers,
    getSingleModifier,
    updateModifier,
    updateModifierAddons,
    createModifier,
    deleteModifier,
    uploadImageModifier,
    deleteImageModifier,
  };
});
