import { Filters } from "~/types/general";

// clearing all chosen filters -> setting updated QSs -> returning params for requests

export const useClearFilters = (filters: Filters) => {
  Object.values(filters).forEach((filter) => {
    // price logic
    if (filter.type === "price") {
      filter.isApplied = false;

      filter.chosenOptions[0].value = filter.options[0].value;
      filter.chosenOptions[1].value = filter.options[1].value;

      return;
    }

    if (filter.type === "priority" || filter.type === "creditService" ) {
      filter.toggle = false;

      filter.chosenOptions = [];

      return;
    }

    if (filter.toggle) {
      filter.toggle = false;
    }

    // for general filters
    filter.chosenOptions = [];
  });

  return useSetFiltersQS(filters);
};
