import { Filters } from "~/types/general";

// removing a single filter -> setting updated QSs -> returning params for requests

export const useRemoveFilter = (
  filters: Filters,
  { type, value }: { type: string; value: any }
) => {
  // price logic
  if (type === "price") {
    filters[type].isApplied = false;

    filters[type].chosenOptions[0].value = filters[type].options[0].value;
    filters[type].chosenOptions[1].value = filters[type].options[1].value;
  } else {
    // for general filters
    filters[type].chosenOptions = filters[type].chosenOptions.filter(
      (o) => o.value !== value
    );
  }

  return useSetFiltersQS(filters);
};
