<template>
  <div class="flow-root">
    <ul role="list">
      <li v-for="(item, index) in history" :key="item.id">
        <div class="relative pb-8">
          <span
            v-if="index !== history.length - 1"
            class="absolute top-5 left-6 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          />

          <div class="relative flex items-start space-x-3">
            <div class="relative">
              <img
                v-if="useGetThumbnail(item?.user?.image)"
                class="flex h-x8 w-x8 items-center justify-center object-cover rounded-x1 bg-gray-400 ring-white"
                :src="useGetThumbnail(item?.user?.image)"
                alt=""
              />
              <div
                v-else-if="
                  useGetCustomerInitials(item?.user) &&
                  !useGetThumbnail(item?.user?.image)
                "
                class="flex rounded-x1 h-x8 w-x8 items-center justify-center bg-grey-500 text-xs"
              >
                <p class="text-white">
                  {{ useGetCustomerInitials(item?.user) }}
                </p>
              </div>
              <img
                v-else
                class="flex h-x8 w-x8 items-center justify-center object-cover rounded-x1 bg-gray-400 ring-white"
                src="~/assets/img/default-user.png"
                alt=""
              />
            </div>

            <div class="min-w-0 flex-1">
              <div>
                <div class="text-sm font-medium text-font-primary">
                  {{ item.user?.name || "System" }}
                </div>

                <p class="mt-0.5 text-xs text-font-secondary">
                  {{ item.text }}
                  -
                  {{ useFormatDate(item.created_at) }}
                </p>
              </div>
              <div v-if="item.meta" class="mt-2 text-sm text-font-primary">
                <!--  PAYMENT  -->
                <template v-if="item.category === 'payment'">
                  <p v-if="item.meta.dashboard_link">
                    Stripe charge
                    {{
                      item.meta.payment_status === "paid"
                        ? "completed"
                        : item.meta.payment_status === "declined"
                          ? "unsuccessful"
                          : item.meta.payment_status === "pending"
                            ? "started"
                            : ""
                    }}
                    <span v-if="item.meta.payment_status !== 'declined'"
                      >{{ useFormatPrice(item.meta.transaction_amount) }}
                    </span>
                    (Charge ID:
                    <a
                      :href="item.meta.dashboard_link"
                      target="_blank"
                      class="text-accent-color"
                      >{{ item.meta.transaction_id }}</a
                    >)
                  </p>
                  <p v-else>
                    {{
                      item.meta.on_account
                        ? "On account"
                        : item.meta.payment_method
                    }}
                    payment
                    {{
                      item.meta.payment_status === OrderPaymentStatus.PAID ||
                      item.meta.on_account
                        ? "completed"
                        : item.meta.payment_status ===
                            OrderPaymentStatus.DECLINED
                          ? "unsuccessful"
                          : item.meta.payment_status ===
                              OrderPaymentStatus.PENDING
                            ? "transaction started"
                            : ""
                    }}
                    {{ useFormatPrice(item.meta.payment_amount) }}
                  </p>
                </template>
                <template v-else>
                  <div v-for="change in item.meta?.changes">
                    <!-- PRODUCT ITEMS (ADDED, REMOVED) -->
                    <div
                      v-if="
                        change.type === OrdersHistoryChangesType.ADDED ||
                        change.type === OrdersHistoryChangesType.REMOVED
                      "
                      class="my-x1 leading-base-md"
                    >
                      <span class="mr-x1">{{
                        change.type === OrdersHistoryChangesType.ADDED
                          ? "Added"
                          : "Removed"
                      }}</span>
                      <span v-if="change.quantity > 1" class="mr-x1"
                        >{{ change.quantity }} units of
                      </span>
                      <span
                        class="text-xs leading-base-sm py-3px px-2.5 cursor-pointer rounded-x10 text-center w-max-content bg-inactive"
                        >{{ change?.name }}</span
                      >
                    </div>
                    <!-- PRODUCT ITEMS CHANGED -->
                    <div
                      v-if="
                        change.type === OrdersHistoryChangesType.ITEM_CHANGED
                      "
                      class="my-x1 leading-base-md"
                    >
                      <span
                        class="text-xs leading-base-sm py-3px px-2.5 cursor-pointer rounded-x10 text-center w-max-content bg-inactive"
                        >{{ change?.name }}</span
                      >
                      <span class="mx-x1">{{ change.attribute }} сhanged</span>
                      <span v-if="change.old || +change.old === 0">
                        {{
                          change.attribute ===
                          OrdersHistoryChangesAttribute.PRICE
                            ? "from"
                            : "of"
                        }}
                        {{ getValue(change.old, change.attribute) }}
                      </span>
                      <span>
                        to
                        {{ getValue(change.new, change.attribute) }}
                      </span>
                    </div>
                    <!-- ATTRIBUTE CHANGED -->
                    <div
                      v-if="
                        change.type ===
                        OrdersHistoryChangesType.ATTRIBUTE_CHANGED
                      "
                      class="my-x1 leading-base-md"
                    >
                      <span>Changed {{ getAttribute(change.attribute) }}</span>
                      <span v-if="change.old">
                        of
                        <span
                          class="text-xs leading-base-sm py-3px px-2.5 cursor-pointer rounded-x10 text-center w-max-content bg-inactive"
                          >{{
                            getStatusTag(change.old, change.attribute)
                          }}</span
                        >
                      </span>
                      <span>
                        to
                        <span
                          class="text-xs leading-base-sm py-3px px-2.5 cursor-pointer rounded-x10 text-center w-max-content bg-inactive"
                          >{{
                            getStatusTag(change.new, change.attribute)
                          }}</span
                        >
                      </span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { useOrdersStore } from "~/store/orders";
import { useGeneralStore } from "~/store/general";
import {
  OrdersHistory,
  OrdersHistoryChangesAttribute,
  OrdersHistoryChangesType,
  OrderPaymentStatus,
} from "~/types/orders";
import { Meta } from "~/types/general";
import { usePurchaseStore } from "~/store/purchase";

const props = defineProps<{
  purchase?: boolean;
}>();

const ordersStore = useOrdersStore();
const purchaseStore = usePurchaseStore();
const generalStore = useGeneralStore();

const route = useRoute();

const history = ref<OrdersHistory[]>([] as OrdersHistory[]);

function getAttribute(attr) {
  return ordersStore.orderHistoryAttributes.find((i) => i.value === attr)?.name;
}

function getStatusTag(status, type) {
  switch (type) {
    case OrdersHistoryChangesAttribute.STATUS: {
      return route.query?.purchase
        ? purchaseStore.statusTags.find((i) => i.value === status)?.name
        : ordersStore.statusTags.find((i) => i.value === status)?.name;
    }
    case OrdersHistoryChangesAttribute.LANE: {
      return status;
    }
    case OrdersHistoryChangesAttribute.TYPE: {
      return generalStore.orderTypeList.find((i) => i.value === status)?.name;
    }
    case OrdersHistoryChangesAttribute.CUSTOMER: {
      return status;
    }
    case OrdersHistoryChangesAttribute.TARGET_DATE: {
      return status;
    }
    case OrdersHistoryChangesAttribute.PAYMENT_METHOD: {
      return status;
    }
  }
}

function getValue(val, type) {
  if (!isNaN(+val)) {
    switch (type) {
      case OrdersHistoryChangesAttribute.PRICE: {
        return useFormatPrice(+val);
      }
      case OrdersHistoryChangesAttribute.QUANTITY: {
        return +val > 1 ? `${+val} units` : `${+val} unit`;
      }
      case OrdersHistoryChangesAttribute.ALLOCATED: {
        return +val > 1 ? `${+val} units` : `${+val} unit`;
      }
    }
  }
}

// GET DATA
const page = ref(1);
const meta = ref<Meta>({} as Meta);

async function getHistory() {
  useIsLoading(true);

  let response;

  const params = {
    page: page.value,
    limit: 40,
    "sort[]": ["pinned-asc", "date-desc"],
  };

  try {
    if (route.name === useRoutesNames().invoicesManageId) {
      response = await ordersStore.getOrderHistory(+route.params?.id, params);
    } else if (route.name === useRoutesNames().billsManageId) {
      response = await purchaseStore.getOrderHistory(+route.params?.id, params);
    } else {
      response = props.purchase
        ? await purchaseStore.getOrderHistory(null, params)
        : await ordersStore.getOrderHistory(null, params);
    }

    history.value =
      page.value === 1 ? response?.data : [...history.value, ...response?.data];

    meta.value = response?.meta;
  } catch (error) {
    console.log(error);
  }

  useIsLoading(false);
}

async function loadMore() {
  if (page.value !== meta.value.last_page) {
    page.value += 1;
    await getHistory();
  }
}

function scrollListener(e) {
  useInfiniteScroll(e, loadMore);
}

onMounted(async () => {
  await getHistory();
  // infinite scroll
  document
    .querySelector("#orders-sidebar")
    .addEventListener("scroll", scrollListener);
});

onBeforeUnmount(() => {
  document
    .querySelector("#orders-sidebar")
    .removeEventListener("scroll", scrollListener);
});
</script>
